.container {
  background-color: #ffffff;
  font-family: "Roboto", sans-serif;
}

.header {
  text-align: center;
  background-color: #f8f8f8;
  padding: 50px;
}

.logo {
  width: 526px;
}

.centerContainer {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.formContainer {
  width: 900px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.textContainer {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.darkText {
  color: #0b459f;
}

.title {
  font-weight: 600;
  font-size: 60px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.button {
  background-color: #3f81e6;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 10px 40px;
  text-align: center;
  text-decoration: none;
  font-size: 20px;
  font-weight: 400;
  margin-top: 20px;
  cursor: pointer;
}

.imageContainer {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.darkContainer {
  background-color: #3f81e6;
  padding-top: 50px;
  padding-bottom: 50px;
}

.lightText {
  color: #ffffff;
}

.description {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.subTitle {
  font-weight: 400;
  font-size: 30px;
}

.divider {
  width: 1px;
  height: 50px;
  background-color: #ffffff;
  margin-top: auto;
  margin-bottom: auto;
}

.label {
  font-weight: 600;
  font-size: 50px;
}

.width60 {
  width: 60%;
}

.width30 {
  width: 30%;
}

.width40 {
  width: 40%;
}

.width50 {
  width: 50%;
}

.width80 {
  width: 80%;
}

.width100 {
  width: 100%;
}

.width20 {
  width: 20%;
  padding-left: 20px;
}

.column {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: auto;
}

.lightButton {
  background-color: #ffffff;
  color: #3f81e6;
  border: none;
  border-radius: 5px;
  padding: 10px 40px;
  text-align: center;
  text-decoration: none;
  font-size: 20px;
  font-weight: 400;
  margin-top: 20px;
  cursor: pointer;
}

.lightContainer {
  background-color: #f8f8f8;
}

.input {
  padding: 12px 20px;
  border: 1px solid #a5a5a5;
  margin-right: 10px;
}

.radio {
  margin: 10px;
}

.disabledButton {
  background-color: #999999;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 10px 40px;
  text-align: center;
  text-decoration: none;
  font-size: 20px;
  font-weight: 400;
  margin-top: 20px;
}

.footer {
  background-color: #344584;
  padding: 50px 150px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.flexWraper {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.office {
  width: 280px;
}

.vdivider {
  width: 100%;
  height: 1px;
  background-color: #d2d2d2;
  margin-top: 10px;
  margin-bottom: 10px;
}

.grayText {
  color: #6e6e6e;
  display: flex;
  gap: 20px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.web {
  display: block;
}
.mobile {
  display: none;
}

img {
  width: 100%;
}

@media screen and (max-width: 600px) {
  .web {
    display: none;
  }
  .mobile {
    display: block;
    margin-top: 20px;
  }
  .header {
    padding: 20px;
  }
  .logo {
    width: 100%;
  }
  .centerContainer {
    text-align: center;
    width: 90%;
    gap: 20px;
  }
  .formContainer {
    text-align: center;
    width: 90%;
    gap: 20px;
  }
  .imageContainer {
    width: 100%;
  }
  .title {
    font-size: 30px;
  }
  .subTitle {
    font-size: 30px;
  }
  .label {
    font-size: 30px;
    text-align: left;
    margin-top: 20px;
  }
  .description {
    font-size: 14px;
    line-height: 120%;
  }
  .width20 {
    width: 40%;
    text-align: left;
  }
  .width40 {
    width: 100%;
  }
  .width60 {
    width: 100%;
  }
  .width60 {
    width: 100%;
  }
  .column {
    text-align: left;
  }
  .darkContainer {
    padding: 20px;
  }
  .textContainer {
    width: 90%;
  }
  .width50 {
    width: 100%;
    margin-top: 10px;
  }
  .width30 {
    width: 100%;
    text-align: left;
  }
  .grayText {
    flex-direction: column;
    gap: 5px;
  }
  .footer {
    padding: 20px;
    font-size: 70%;
    gap: 10px;
  }
  .office {
    width: 150px;
  }
  .centerLabel {
    font-weight: 600;
    font-size: 30px;
    text-align: center;
  }
  img {
    margin-top: 20px;
  }
}
